import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FaUser, FaFileDownload } from "react-icons/fa";
import { FaTag } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { SiLevelsdotfyi } from "react-icons/si";
import {
  gamesActions,
  marketActions,
  walletActions,
  userActions,
  appSettingActions,
} from "../../_actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "./ViewGameModal";
import profileimg from "./profile.png";
import ChangePasswordModel from "./ChangePasswordModel";
import { FaPercent } from "react-icons/fa";

const Dashboard = () => {
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const { gamesList, gamesTotal } = useSelector((state) => state.games);
  const today = new Date().toISOString().split("T")[0];
  const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const { adminProfileDetails } = useSelector((state) => state.users);
  let [rowData, setRowData] = useState(null);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [gameIds, setgameIds] = useState("");
  const [marketIds, setmarketIds] = useState("");
  const [fromDates, setfromDates] = useState("");
  const [openmodel, setopenmodel] = useState(false);

  let sessiondata = JSON.parse(window.sessionStorage.getItem("adminuser"));

  // console.log("sessiondatasessiondatasessiondata",sessiondata)

  useEffect(() => {
    const fetchData = async () => {
      let gameReq = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: 1000,
      };

      dispatch(gamesActions.getAllGames(gameReq));
      dispatch(userActions.getAdminProfile());

      let paymentListReq = {
        type: "DEPOSIT",
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      dispatch(walletActions.getPaymentListForAdmin(paymentListReq));
      dispatch(userActions.getTotalCount());
      dispatch(gamesActions.getAllMarket());

      let obj = {
        gameId: gameIds,
        marketId: marketIds,
        fromDate: fromDates,
      };

      dispatch(walletActions.getBetsTotalCount(obj));


      let appsettingReq = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }
      dispatch(appSettingActions.getAppSettingList(appsettingReq));

    };

    // if(sessiondata){

    fetchData();
    // }
  }, []);

  const createGameSubmit = (e) => {
    e.preventDefault();

    let obj = {
      gameId: gameIds || "",
      marketId: marketIds || "",
      fromDate: fromDates || "",
    };
    dispatch(walletActions.getBetsTotalCount(obj));
  };

  const deleteGame2 = (data, elem) => {
    console.log(data, elem);
    let gameIdReq = {
      paymentId: data._id,
      status: elem,
    };

    let gamePageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      type: "DEPOSIT",
      pageNo: pageNo,
      size: size,
    };

    confirmAlert({
      title: "Confirm to Change Status?",
      message: `Are you sure you want to change Status of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
    setViewModal(false);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
    setRowData({});
  };

  let { users, wallet ,appSettings} = selector ? selector : {};

  let { games } = selector ? selector : {};
  let { appSettingList } = appSettings ? appSettings : {};
  let { allMarket, allUserList } = games ? games : {};
  let { totalCountBet } = wallet ? wallet : {};
  let { totalCounts } = users ? users : {};
  let { loading } = users ? users : {};

  console.log("adminProfileDetails?.adminData?.roleId?>>>>>",adminProfileDetails);

  return (
    <>
      <Loader loading={loading} />
      <div className=" bg-pink-500 text-white mt-1 flex justify-center items-center">

      {/* <marquee behavior="scroll" direction="left" scrollamount="8">
{appSettingList&&appSettingList?.length>0&&appSettingList.filter(e=>e.key=="WhatsApp")[0]?.value}
</marquee> */}
      </div>

      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-3">
                      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-3">
                        <div className="w-full h-full">
                          <div className="w-full bg-white rounded-xl">
                            <div className=" relative bg-pink-500 bg-no-repeat rounded-t-xl p-4 pb-8 flex flex-col justify-start">
                              <img
                                src={profileimg}
                                className=" w-1/2 h-32 -top-4  scale-75 right-0 absolute"
                              />
                              <span className="text-white font-semibold">
                                Welcome Back!
                              </span>
                              <span className="text-white capitalize">
                                {adminProfileDetails &&
                                adminProfileDetails?.adminData?.userName
                                  ? adminProfileDetails?.adminData?.userName
                                  : ""}{" "}
                                Dashboard
                              </span>
                            </div>
                            <div className="w-full p-4 pb-6 flex  items-start">
                              <div className="relative w-full flex flex-col justify-start">
                                <div className="absolute -top-8  w-28 h-28 p-1 rounded-full">
                                  <img
                                    src="/Img/profile.png"
                                    className="rounded-full w-20 h-20"
                                    alt="logo"
                                  />
                                  <button
                                    onClick={() => setopenmodel((e) => !e)}
                                    className=" text-[12px] whitespace-nowrap bg-pink-500 text-white px-1 py-1 rounded-lg "
                                  >
                                    Change Password
                                  </button>
                                </div>
                              </div>
                              <div className=" flex w-full md:flex-row flex-col">
                                <div className="w-full flex flex-col justify-start">
                                  <span className="text-black font-semibold ">
                                    {totalCounts?.data &&
                                    totalCounts?.data?.totalActiveUsers
                                      ? totalCounts?.data?.totalActiveUsers
                                      : 0}
                                  </span>
                                  <span className="text-black whitespace-nowrap">
                                    Active Users
                                  </span>
                                </div>

                                <div className="w-full flex flex-col justify-start">
                                  <span className="text-black font-semibold">
                                    {totalCounts?.data &&
                                    totalCounts?.data?.totalDeactiveUsers
                                      ? totalCounts?.data?.totalDeactiveUsers
                                      : 0}
                                  </span>
                                  <span className="text-black whitespace-nowrap">
                                    Inactive Users
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="p-4 flex flex-col justify-start">
                              {/* <span className='text-black font-semibold'>Taj777</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="w-full lg:col-span-2  space-y-3">
                          <div className="grid lg:grid-cols-3 gap-3">
                            <Link
                             
                              className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5 cursor-default"
                            >
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>Total Users</span>
                                <span className="font-semibold text-gray-600">
                                  {totalCounts?.data?.totalUserCount || 0}
                                </span>
                              </div>
                              <div className="bg-pink-500 w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <FaUser />
                              </div>
                            </Link>
                            <Link
                              to="/app/game"
                              className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5"
                            >
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>Games</span>
                                <span className="font-semibold text-gray-600">
                                  {totalCounts?.data?.totalGameCount || 0}
                                </span>
                              </div>
                              <div className="bg-pink-500 w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <FaFileDownload />
                              </div>
                            </Link>
                            <Link
                              to="/app/userbidhistory"
                              className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5"
                            >
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>Players (Today)</span>
                                <span className="font-semibold text-gray-600">
                                  {totalCounts?.data?.todaysPlayerCount || 0}
                                </span>
                              </div>
                              <div className="bg-pink-500 w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <FaTag />
                              </div>
                            </Link>
                            {/* <div
                              to="/app/userbidhistory"
                              className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5"
                            >
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>
                                  My Share :{" "}
                                  {adminProfileDetails &&
                                  adminProfileDetails?.adminData &&
                                  adminProfileDetails?.adminData
                                    ? adminProfileDetails?.adminData?.share
                                    : "0"}{" "}
                                  %
                                </span>
                                <span>
                                  My Commission :{" "}
                                  {adminProfileDetails &&
                                  adminProfileDetails?.adminData &&
                                  adminProfileDetails?.adminData
                                    ? adminProfileDetails?.adminData?.comm
                                    : "0"}{" "}
                                  %
                                </span>
                              </div>
                              <div className="bg-pink-500 w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <FaPercent />
                              </div>
                            </div> */}
                            <div
                              to="/app/userbidhistory"
                              className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5"
                            >
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>
                                  My Level :{" "}
                                {adminProfileDetails?.adminData?.roleId==0 && "Super Admin"}
                                {adminProfileDetails?.adminData?.roleId==1 && "Admin"}
                                {adminProfileDetails?.adminData?.roleId==2 && "Super MASTER"}
                                {adminProfileDetails?.adminData?.roleId==3 && "MASTER"}
                                {adminProfileDetails?.adminData?.roleId==4 && "AGENT"}
                                {adminProfileDetails?.adminData?.roleId==20 && "OPERATOR"}
                                </span>
                              </div>
                              <div className="bg-pink-500 w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <SiLevelsdotfyi />
                              </div>
                            </div>

                            { sessiondata&&sessiondata.roleId!==0&&<div
                              to="/app/userbidhistory"
                              className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5"
                            >
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>
                                  Balance :{" "}
                                  {adminProfileDetails &&
                                  adminProfileDetails?.adminData &&
                                  adminProfileDetails?.adminData&&adminProfileDetails?.balance?.toString()}
                                  
                                </span>
                                
                              </div>
                              <div className="bg-pink-500 w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <FaRupeeSign />
                              </div>
                            </div>}



                          </div>
                        </div>
                      </div>
                      <div className="bg-white rounded-xl">
                        <div className="flex justify-between items-center px-3 border-b">
                          <span className="p-5 text-lg text-left text-gray-900 bg-white">
                            Bids Report
                          </span>
                        </div>

                        <div className="p-5 space-y-2">
                          <div className="flex md:flex-row flex-col items-center justify-center md:space-x-5  space-y-2 md:space-y-0">
                            <div className="mt-1 flex-1 shadow-sm w-full">
                              <label
                                class="block text-gray-700 text-base mb-2"
                                for="name"
                              >
                                Date
                              </label>
                              <input
                                className="w-full px-3 py-2 text-sm bg-white border border-[#C11C84] rounded-xl focus:outline-none"
                                id="fromDate"
                                name="fromDate"
                                value={fromDates}
                                max={today}
                                type="date"
                                onChange={(e) => setfromDates(e.target.value)}
                              />
                            </div>
                            <div className="mt-1 flex-1 shadow-sm w-full">
                              <label
                                class="block text-gray-700 text-base mb-2"
                                for="name"
                              >
                                Game Name
                              </label>
                              <select
                                className="w-full px-3 py-2 text-sm capitalize bg-white border border-[#C11C84] rounded-xl focus:outline-none"
                                id="userId"
                                name="userId"
                                placeholder="name"
                                value={gameIds}
                                type="text"
                                onChange={(e) => setgameIds(e.target.value)}
                              >
                                <option value="">Select Game</option>
                                {gamesList && gamesList.length > 0
                                  ? gamesList.map((element, index) => (
                                      <option
                                        value={
                                          element && element._id
                                            ? element._id
                                            : ""
                                        }
                                      >
                                        {element && element.name
                                          ? element.name
                                          : ""}
                                      </option>
                                    ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["userId"] ? (
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["userId"]}
                                </div>
                              ) : null}
                            </div>
                            <div className="mt-1 flex-1 shadow-sm w-full">
                              <label
                                class="block text-gray-700 text-base mb-2"
                                for="name"
                              >
                                Market Name
                              </label>
                              <select
                                className="w-full px-3 py-2 text-sm bg-white border border-[#C11C84] rounded-xl focus:outline-none"
                                id="userId"
                                name="userId"
                                placeholder="name"
                                value={marketIds}
                                type="text"
                                onChange={(e) => setmarketIds(e.target.value)}
                              >
                                <option value="">Select Market</option>
                                {allMarket && allMarket.length > 0
                                  ? allMarket.map((element, index) => (
                                      <option
                                        value={
                                          element && element._id
                                            ? element._id
                                            : ""
                                        }
                                      >
                                        {element && element.name
                                          ? element.name
                                          : ""}
                                      </option>
                                    ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["userId"] ? (
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["userId"]}
                                </div>
                              ) : null}
                            </div>
                            <div className="mt-1 flex-1 shadow-sm w-full">
                              <label
                                class="block text-gray-700 text-base mb-8"
                                for="name"
                              >
                                {" "}
                                {""}
                              </label>
                              <button
                                className="px-8 py-2 w-full text-base font-semibold text-white capitalize  bg-pink-500 border border-[#992941] rounded-xl"
                                type="button"
                                onClick={createGameSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white w-full grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 rounded-xl">
                        <div className="w-full space-y-2">
                          <div className="flex justify-between items-center border-[1px] border-dashed border-[#C11C84] p-2">
                            <span className="font-medium">
                              Total Bets Count
                            </span>
                            <span className="font-medium">
                              {totalCountBet?.data?.totalBets || 0}
                            </span>
                            {/* <button className="px-4 py-1 font-medium text-white capitalize  bg-pink-500 border border-[#992941] rounded-full" type="button" onClick={createGameSubmit}>View</button> */}
                          </div>
                          <div className="flex justify-between items-center border-[1px] border-dashed border-[#C11C84] 46] p-2">
                            <span className="font-medium">
                              Total Bet Amount
                            </span>
                            <span className="font-medium">
                              {totalCountBet?.data?.totalWinAmount || 0}
                            </span>
                            {/* <button className="px-4 py-1 font-medium text-white capitalize  bg-pink-500 border border-[#992941] rounded-full" type="button" onClick={createGameSubmit}>View</button> */}
                          </div>
                          {/* <div className='flex justify-between items-center border-[1px] border-dashed border-[#C11C84] p-2'>
                            <span className='font-medium'>Total Profit Amount</span>
                            <span className='font-medium'>6sad0</span>
                            // <button className="px-4 py-1 font-medium text-white capitalize  bg-pink-500 border border-[#992941] rounded-full" type="button" onClick={createGameSubmit}>View</button>
                          </div> */}
                        </div>
                        <div className="w-full space-y-2">
                          <div className="flex justify-between items-center border-[1px] border-dashed border-[#C11C84] p-2">
                            <span className="font-medium">Total user Winning</span>
                            <span className="font-medium">
                              {totalCountBet?.data?.totalWins || 0}
                            </span>
                            {/* <button className="px-4 py-1 font-medium text-white capitalize  bg-pink-500 border border-[#992941] rounded-full" type="button" onClick={createGameSubmit}>View</button> */}
                          </div>
                          <div className="flex justify-between items-center border-[1px] border-dashed border-[#C11C84] p-2">
                            <span className="font-medium">Total user Loss</span>
                            <span className="font-medium">
                              {totalCountBet?.data?.totalLosses || 0}
                            </span>
                            {/* <button className="px-4 py-1 font-medium text-white capitalize  bg-pink-500 border border-[#992941] rounded-full" type="button" onClick={createGameSubmit}>View</button> */}
                          </div>
                          {/* <div className='flex justify-between items-center border-[1px] border-dashed border-black p-2'>
                            <span className='font-medium'>Add Funds (Manually)</span>
                            <span className='font-medium'>60</span>
                            // <button className="px-4 py-1 font-medium text-white capitalize  bg-pink-500 border border-[#992941] rounded-full" type="button" onClick={createGameSubmit}>View</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangePasswordModel openmodel={openmodel} setopenmodel={setopenmodel} />
      <ViewGameModal
        rowData={rowData}
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        deleteGame2={deleteGame2}
      />
    </>
  );
};

export default Dashboard;
