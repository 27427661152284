export const gamesConstants = {
    GET_ALL_USER_REQUEST: "GET_ALL_USER_REQUEST",
    GET_ALL_USER_SUCCESS: "GET_ALL_USER_SUCCESS",
    GET_ALL_USER_FAILURE: "GET_ALL_USER_FAILURE",
    RESULT_BY_ADMIN_REQUEST: "RESULT_BY_ADMIN_REQUEST",
    RESULT_BY_ADMIN_SUCCESS: "RESULT_BY_ADMIN_SUCCESS",
    RESULT_BY_ADMIN_FAILURE: "RESULT_BY_ADMIN_FAILURE",
    NUMBER_REQUEST: "NUMBER_REQUEST",
    NUMBER_SUCCESS: "NUMBER_SUCCESS",
    NUMBER_FAILURE: "NUMBER_FAILURE",

    ONOFF_BRAND_REQUEST:"ONOFF_BRAND_REQUEST",
    ONOFF_BRAND_SUCCESS:"ONOFF_BRAND_SUCCESS",
    ONOFF_BRAND_FAILURE:"ONOFF_BRAND_FAILURE",

    GET_ALL_GAMESBRAND_REQUEST:"GET_ALL_GAMESBRAND_REQUEST",
    GET_ALL_GAMESBRAND_SUCCESS:"GET_ALL_GAMESBRAND_SUCCESS",
    GET_ALL_GAMESBRAND_FAILURE:"GET_ALL_GAMESBRAND_FAILURE",


    GET_ALL_GAMES_JODI_REQUEST:"GET_ALL_GAMES_JODI_REQUEST",
    GET_ALL_GAMES_JODI_SUCCESS:"GET_ALL_GAMES_JODI_SUCCESS",
    GET_ALL_GAMES_JODI_FAILURE:"GET_ALL_GAMES_JODI_FAILURE",


    DELETE_BRAND_REQUEST:"DELETE_BRAND_REQUEST",
    DELETE_BRAND_SUCCESS:"DELETE_BRAND_SUCCESS",
    DELETE_BRAND_FAILURE:"DELETE_BRAND_FAILURE",

    CREATE_BRAND_REQUEST:"CREATE_BRAND_REQUEST",
    CREATE_BRAND_SUCCESS:"CREATE_BRAND_SUCCESS",
    CREATE_BRAND_FAILURE:"CREATE_BRAND_FAILURE",



    UPDATE_BRAND_STATUS_REQUEST:"UPDATE_BRAND_STATUS_REQUEST",
    UPDATE_BRAND_STATUS_SUCCESS:"UPDATE_BRAND_STATUS_SUCCESS",
    UPDATE_BRAND_STATUS_FAILURE:"UPDATE_BRAND_STATUS_FAILURE",



    GETALL_GAME_ADMIN_REQUEST: "GETALL_GAME_ADMIN_REQUEST",
    GETALL_GAME_ADMIN_SUCCESS: "GETALL_GAME_ADMIN_SUCCESS",
    GETALL_GAME_ADMIN_FAILURE: "GETALL_GAME_ADMIN_FAILURE",

    UPDATE_BRAND_REQUEST: "UPDATE_BRAND_REQUEST",
    UPDATE_BRAND_SUCCESS: "UPDATE_BRAND_SUCCESS",
    UPDATE_BRAND_FAILURE: "UPDATE_BRAND_FAILURE",
    GET_ALL_BRAND_REQUEST: "GET_ALL_BRAND_REQUEST",
    GET_ALL_BRAND_SUCCESS: "GET_ALL_BRAND_SUCCESS",
    GET_ALL_BRAND_FAILURE: "GET_ALL_BRAND_FAILURE",


    NUMBER_ARRAY_REQUEST: "NUMBER_ARRAY_REQUEST",
    NUMBER_ARRAY_SUCCESS: "NUMBER_ARRAY_SUCCESS",
    NUMBER_ARRAY_FAILURE: "NUMBER_ARRAY_FAILURE",

    ROLL_BACK_BACK_REQUEST: "ROLL_BACK_BACK_REQUEST",
    ROLL_BACK_BACK_SUCCESS: "ROLL_BACK_BACK_SUCCESS",
    ROLL_BACK_BACK_FAILURE: "ROLL_BACK_BACK_FAILURE",


    GET_WINNER_LIST_REQUEST: "GET_WINNER_LIST_REQUEST",
    GET_WINNER_LIST_SUCCESS: "GET_WINNER_LIST_SUCCESS",
    GET_WINNER_LIST_FAILURE: "GET_WINNER_LIST_FAILURE",

    GET_PAYMENT_ADMIN1_REQUEST: "GET_PAYMENT_ADMIN1_REQUEST",
    GET_PAYMENT_ADMIN1_SUCCESS: "GET_PAYMENT_ADMIN1_SUCCESS",
    GET_PAYMENT_ADMIN1_FAILURE: "GET_PAYMENT_ADMIN1_FAILURE",
    SAVE_ADMIN_RESULT_REQUEST: "SAVE_ADMIN_RESULT_REQUEST",
    SAVE_ADMIN_RESULT_SUCCESS: "SAVE_ADMIN_RESULT_SUCCESS",
    SAVE_ADMIN_RESULT_FAILURE: "SAVE_ADMIN_RESULT_FAILURE",

    GET_RESULT_LIST_ADMIN_REQUEST: "GET_RESULT_LIST_ADMIN_REQUEST",
    GET_RESULT_LIST_ADMIN_SUCCESS: "GET_RESULT_LIST_ADMIN_SUCCESS",
    GET_RESULT_LIST_ADMIN_FAILURE: "GET_RESULT_LIST_ADMIN_FAILURE",

    GET_ALL_MARKETS_REQUEST: "GET_ALL_MARKETS_REQUEST",
    GET_ALL_MARKETS_SUCCESS: "GET_ALL_MARKETS_SUCCESS",
    GET_ALL_MARKETS_FAILURE: "GET_ALL_MARKETS_FAILURE",

    CREATE_GAMES_REQUEST: 'CREATE_GAMES_REQUEST',
    CREATE_GAMES_SUCCESS: 'CREATE_GAMES_SUCCESS',
    CREATE_GAMES_FAILURE: 'CREATE_GAMES_FAILURE',

    GET_ALL_GAMES_REQUEST: 'GET_ALL_GAMES_REQUEST',
    GET_ALL_GAMES_SUCCESS: 'GET_ALL_GAMES_SUCCESS',
    GET_ALL_GAMES_FAILURE: 'GET_ALL_GAMES_FAILURE',

    GET_GAMES_LIST_REQUEST: 'GET_GAMES_LIST_REQUEST',
    GET_GAMES_LIST_SUCCESS: 'GET_GAMES_LIST_SUCCESS',
    GET_GAMES_LIST_FAILURE: 'GET_GAMES_LIST_FAILURE',

    UPDATE_GAMES_REQUEST: 'UPDATE_GAMES_REQUEST',
    UPDATE_GAMES_SUCCESS: 'UPDATE_GAMES_SUCCESS',
    UPDATE_GAMES_FAILURE: 'UPDATE_GAMES_FAILURE',

    UPDATE_GAMES_STATUS_REQUEST: 'UPDATE_GAMES_STATUS_REQUEST',
    UPDATE_GAMES_STATUS_SUCCESS: 'UPDATE_GAMES_STATUS_SUCCESS',
    UPDATE_GAMES_STATUS_FAILURE: 'UPDATE_GAMES_STATUS_FAILURE',

    DELETE_GAMES_REQUEST: 'DELETE_GAMES_REQUEST',
    DELETE_GAMES_SUCCESS: 'DELETE_GAMES_SUCCESS',
    DELETE_GAMES_FAILURE: 'DELETE_GAMES_FAILURE',

};
