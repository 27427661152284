import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { FaHome } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { LuGamepad2 } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";
// import { IoMdMenu } from "react-icons/io";
import { GiAngelWings } from "react-icons/gi";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowRight } from "react-icons/md";
import profitTowin from "./profitTowin.png";
import { BsGraphUpArrow } from "react-icons/bs";
import { TbReportMoney } from "react-icons/tb";
import { TfiWallet } from "react-icons/tfi";
import { TbSettingsUp } from "react-icons/tb";




function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  return (
    <>
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          } absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-white focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 `}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          ></path>
        </svg>
      </button>
      {/* lg:flex lg:static  */}
      <div
        className={`${navbarOpen === false ? "hidden" : "flex"
          } fixed left-0 inset-y-0   w-72 z-50 flex-col`}
      >
        <div className="flex flex-col w-72 relative flex-1 h-full max-h-full">
          {/* onClick={() => onClickMenu('/app/dashboard')} */}
          <div className="cursor-pointer relative flex justify-between place-items-center border-b-2 flex-shrink-0 bg-pink-500  w-full z-50">
            <h1
              className="text-3xl text-center p-[1.36rem] font-bold bg-gradient-to-r from-[#d4d0d4] to-[#f8f2f7] bg-clip-text text-transparent"
            >
              247 skyexch
            </h1>

            <div className="">
              <GiHamburgerMenu
                onClick={handleNavbar}
                className=" text-white text-xl  block mx-4"
              />
            </div>
          </div>
          <nav className="overflow-y-auto bg-white flex flex-col flex-wrap items-center justify-between relative  rounded-e-xl w-72 z-10 px-3  h-full overflow-auto">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="">
                  <span
                    onClick={() => onClickMenu("/app/dashboard")}
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/dashboard"
                      ? "text-white bg-pink-500 rounded-md"
                      : "text-black   hover:text-[#992941]"
                      }`}
                  >
                    <span className="group-hover:text-[#992941]">
                      <FaHome size={20} className=""/>
                    </span>
                    <span className=" font-semibold text-base sidebar_font  sidebar_font ">Dashboard</span>
                  </span>
                  {/* {localStoragedata&&localStoragedata.roleId !==0 &&  <span onClick={() => onClickMenu('/app/user')} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/user" ||
                                        location.pathname.includes("/app/user/") ||
                                        location.pathname.includes("/app/EditUser/")
                                        ? "text-white bg-pink-500 rounded-md"
                                        : "text-black"
                                        }`}>
                                        <span className="group-hover:text-[#992941]"><FaUserGear size={20} /></span>
                                        <span className=" font-semibold text-base sidebar_font ">User Management</span>
                                    </span>} */}
                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResult')} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult" ? "text-white bg-pink-500 rounded-md" : "text-black   hover:text-[#992941]"}`}>
                                            <span className="group-hover:text-[#992941]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare Result</span>
                                        </span>} */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResultStarline')} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultStarline" ? "text-white bg-pink-500 rounded-md" : "text-black   hover:text-[#992941]"}`}>
                                            <span className="group-hover:text-[#992941]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare FataFat Result</span>
                                        </span>} */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <span
                      onClick={() => onClickMenu("/app/winnerList")}
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/winnerList"
                        ? "text-white bg-pink-500 rounded-md"
                        : "text-black   hover:text-[#992941]"
                        }`}
                    >
                      <span className="group-hover:text-[#992941]">
                        <GiAngelWings size={20} />
                      </span>
                      <span className=" font-semibold text-base sidebar_font  sidebar_font ">Winner List</span>
                    </span>
                  )}
                

                  {/* <span onClick={() => onClickMenu('/app/appsetting',handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting" ? "text-white bg-pink-500 rounded-md" : "text-black   hover:text-[#992941]" }`}>
                                        <span className="group-hover:text-[#992941]"><MdOutlineCreditCard size={20} /></span>
                                        <span className=" font-semibold text-base sidebar_font ">App Setting Management</span>
                                    </span> */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 && <span onClick={() => onClickMenu('/app/bankdetails')} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/bankdetails" ? "text-white bg-pink-500 rounded-md" : "text-black   hover:text-[#992941]"}`}>
                                        <span className="group-hover:text-[#744939]"><BsBank2 size={20} /></span>
                                        <span className=" font-semibold text-base sidebar_font ">Bank Management</span>
                                    </span>} */}
                  {/* SUPER_ADMIN: 0,
                ADMIN: 1,
                SUPER_MASTER: 2,
                MASTER: 3,
                AGENT: 4,
                OPERATOR: 20,
                CLIENT: 100 */}
                  <span
                    onClick={() => onClickMenu("/app/downsideUser")}
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/downsideUser"
                      ? "text-white bg-pink-500 rounded-md"
                      : "text-black   hover:text-[#992941]"
                      }`}
                  >
                    <span className="group-hover:text-[#992941]">
                      <ImUsers size={20} />
                    </span>
                    {localStoragedata && localStoragedata.roleId == 0 && (
                      <span className=" font-semibold text-base sidebar_font ">ADMIN</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 1 && (
                      <span className=" font-semibold text-base sidebar_font ">SUPER MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 2 && (
                      <span className=" font-semibold text-base sidebar_font ">MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 3 && (
                      <span className=" font-semibold text-base sidebar_font ">AGENT</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 4 && (
                      <span className=" font-semibold text-base sidebar_font ">OPERATOR</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 20 && (
                      <span className=" font-semibold text-base sidebar_font ">CLIENT</span>
                    )}
                  </span>

                  {/* <span onClick={() => onClickMenu('/app/notification')} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/notification" ? "text-white bg-pink-500 rounded-md" : "text-black   hover:text-[#992941]"}`}>
                                        <span className="group-hover:text-[#992941]"><MdNotificationsNone size={20} /></span>
                                        <span className=" font-semibold text-base sidebar_font ">Notification Managemnt</span>
                                    </span> */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <div className="cursor-pointer group">
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#992941]">
                            <TbSettingsUp size={20} />
                          </span>
                          <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#992941]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#992941]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#992941]">
                            <TbSettingsUp size={20} />{" "}
                          </span>
                          <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#992941]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#992941]" />
                          )}
                        </span>
                      )}
                      {showSection === "settingManagement" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() => onClickMenu("/app/appsetting")}
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting"
                              ? "text-white bg-pink-500 rounded-md"
                              : "text-black   hover:text-[#992941]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className=" font-semibold text-base sidebar_font ">
                              App Setting
                            </span>
                          </span>
                          <span
                            onClick={() => onClickMenu("/app/slider")}
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/slider"
                              ? "text-white bg-pink-500 rounded-md"
                              : "text-black   hover:text-[#992941]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className=" font-semibold text-base sidebar_font ">
                              Slider Setting
                            </span>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}

                
                </div>

                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#992941]">
                          <LuGamepad2 size={20} />
                        </span>
                        <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#992941]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#992941]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("game")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#992941]">
                          <LuGamepad2 size={20} />{" "}
                        </span>
                        <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#992941]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#992941]" />
                        )}
                      </span>
                    )}
                    {showSection === "game" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        {/* <span
                          onClick={() =>
                            onClickMenu("/app/brand")
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                            location.pathname === "/app/brand"
                              ? "text-white bg-pink-500 rounded-md"
                              : "text-black   hover:text-[#992941]"
                          }`}
                        >
                        
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">Brand </span>
                        </span> */}

                        <span
                          onClick={() => onClickMenu("/app/game")}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/game"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#992941]"><LuGamepad2 size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">Game </span>
                        </span>
                        <span
                          onClick={() => onClickMenu("/app/Brandgame")}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/Brandgame"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#992941]"><LuGamepad2 size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            Indian Starline Games{" "}
                          </span>
                        </span>
                        <span
                          onClick={() => onClickMenu("/app/IndianJackpots")}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/IndianJackpots"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#992941]"><LuGamepad2 size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            Indian Jackpot{" "}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}


                {/* 
                {localStoragedata && localStoragedata.roleId == 0 && ( */}
                <div className="cursor-pointer group">
                  {showSection === "walletMangment" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#992941]">
                        <TfiWallet size={20} />
                      </span>
                      <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                        Wallet Management
                      </span>
                      {showSection === "walletMangment" ? (
                        <HiChevronUp className="group-hover:text-[#992941]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#992941]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("walletMangment")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#992941]">
                        <TfiWallet size={20} />{" "}
                      </span>
                      <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                        Wallet Management
                      </span>
                      {showSection === "walletMangment" ? (
                        <HiChevronUp className="group-hover:text-[#992941]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#992941]" />
                      )}
                    </span>
                  )}
                  {showSection === "walletMangment" ? (
                    <div className=" mb-5  flex flex-col pl-10">

                      <span
                        onClick={() => onClickMenu("/app/AddFundRequest")}
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/game"
                          ? "text-white bg-pink-500 rounded-md"
                          : "text-black   hover:text-[#992941]"
                          }`}
                      >
                        {/* <span className="group-hover:text-[#992941]"><LuGamepad2 size={20} /></span> */}
                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">Add Fund Report </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawRequest")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/addFundReport"
                          ? "text-white bg-pink-500 rounded-md"
                          : "text-black   hover:text-[#992941]"
                          }`}
                      >

                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">Withdraw Fund Report</span>
                      </span>


                      <span
                        onClick={() =>
                          onClickMenu("/app/addFund")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/addFund"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                          }`}
                      >

                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">Add Fund </span>
                      </span>

                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawfund")
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/withdrawfund"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                          }`}
                      >

                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">Withdraw Fund </span>
                      </span>


                    </div>
                  ) : null}
                </div>
                {/* )} */}




                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "mm" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#992941]">
                          <BsGraphUpArrow size={20} />
                        </span>
                        <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                          Market Management
                        </span>
                        {showSection === "mm" ? (
                          <HiChevronUp className="group-hover:text-[#992941]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#992941]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("mm")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#992941]">
                          <BsGraphUpArrow size={20} />{" "}
                        </span>
                        <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                          Market Management
                        </span>
                        {showSection === "mm" ? (
                          <HiChevronUp className="group-hover:text-[#992941]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#992941]" />
                        )}
                      </span>
                    )}
                    {showSection === "mm" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() => onClickMenu("/app/market")}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/market"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#992941]"><FaShoppingBasket size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            Market Management
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu("/app/marketJackpot")}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/marketJackpot"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#992941]"><FaShoppingBasket size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            Jackpot Management
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu("/app/marketStarLine")}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/marketStarLine"
                            ? "text-white bg-pink-500 rounded-md"
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#992941]"><FaShoppingBasket size={20} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            StarLine Management
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "resultDeclare" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#992941]">
                          <TbReportMoney size={20} />
                        </span>
                        <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#992941]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#992941]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("resultDeclare")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#992941]">
                          <TbReportMoney size={20} />{" "}
                        </span>
                        <span className="text-base group-hover:text-[#992941]  lg:font-lg sidebar_font">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#992941]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#992941]" />
                        )}
                      </span>
                    )}
                    {showSection === "resultDeclare" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() => onClickMenu("/app/declareResult")}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult"
                            ? "bg-pink-500 text-white  rounded-lg "
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            Game Result Game
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu(
                              "/app/declareResultStarline",
                              handleNavbar()
                            )
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultStarline"
                            ? "bg-pink-500 text-white  rounded-lg "
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            Indian StartLine
                          </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu(
                              "/app/declareResultJackPot",
                              handleNavbar()
                            )
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultJackPot"
                            ? "bg-pink-500 text-white  rounded-lg "
                            : "text-black   hover:text-[#992941]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className=" font-semibold text-base sidebar_font ">
                            Indian JackPot
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                <div className="cursor-pointer group">
                  {showSection === "report" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#992941]">
                        <TbReportAnalytics size={20} />
                      </span>
                      <span className=" group-hover:text-[#992941]  lg:font-lg sidebar_font text-base">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#992941]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#992941]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("report")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-black transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#992941]">
                        <TbReportAnalytics size={20} />{" "}
                      </span>
                      <span className=" group-hover:text-[#992941]  lg:font-lg sidebar_font text-base">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#992941]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#992941]" />
                      )}
                    </span>
                  )}
                  {showSection === "report" ? (
                    <div className=" mb-5  flex flex-col pl-10">
                      <span
                        onClick={() => onClickMenu("/app/betHistory")}
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/betHistory"
                          ? "bg-pink-500 text-white  rounded-lg "
                          : "text-black   hover:text-[#992941]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">
                          Bid Point History
                        </span>
                      </span>
                      <span
                        onClick={() => onClickMenu("/app/userbidhistory")}
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/userbidhistory"
                          ? "bg-pink-500 text-white  rounded-lg "
                          : "text-black   hover:text-[#992941]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">
                          User Bid History
                        </span>
                      </span>

                      <span
                        onClick={() => onClickMenu("/app/ledger")}
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/ledger"
                          ? "bg-pink-500 text-white  rounded-lg "
                          : "text-black   hover:text-[#992941]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">
                          My Point Statement
                        </span>
                      </span>

                      {/* <span
                        onClick={() => onClickMenu("/app/betsledger")}
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betsledger"
                            ? "bg-pink-500 text-white  rounded-lg "
                            : "text-black   hover:text-[#992941]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className=" font-semibold text-base sidebar_font ">My Ledger</span>
                      </span> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
