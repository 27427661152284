import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../_actions";
import LoginJSON from "./login.json";
import { useNavigate } from "react-router";
import { LuEyeOff } from "react-icons/lu";
import { LuEye } from "react-icons/lu";
import logo from "./logo.png";
import Loader from "../../components/Loader/Loader";
import background from "./background.png";
import { FaMobileAlt } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";


import { useEffect } from 'react';

const ScrollFixOnKeyboard = () => {
  useEffect(() => {
    const handleFocus = (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        const yOffset = window.pageYOffset;
        setTimeout(() => {
          window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }, 300);  // Adjust the scroll delay slightly to account for keyboard animation
      }
    };

    const handleBlur = () => {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll back when keyboard is dismissed
      }, 300); // Adjust the scroll delay slightly to account for keyboard animation
    };

    document.addEventListener('focusin', handleFocus);
    document.addEventListener('focusout', handleBlur);

    return () => {
      document.removeEventListener('focusin', handleFocus);
      document.removeEventListener('focusout', handleBlur);
    };
  }, []);

  return null;
};







const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fieldslogin, setFieldsLogin] = useState({});
  const [errorslogin, setErrorsLogin] = useState({});
  let selector = useSelector((state) => state);
  // console.log("---------------->",selector)
  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(name === "userName" && value.length > 10)
    if (name === "userName" && value.length > 10) {
      return;
    } else {

      setFieldsLogin((prevState) => ({ ...prevState, [name]: value }));
      setErrorsLogin((prevState) => ({ ...prevState, [name]: "" }));
    }
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if (handleValidationLogin()) {
      const { userName, password } = fieldslogin;
      dispatch(
        userActions.login({ userName: userName, password: password }, navigate)
      );
    }
  };

  const handleValidationLogin = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldslogin.userName || fieldslogin.userName === "") {
      formIsValid = false;
      errors.userName = LoginJSON.ErrorMsg.email;
    }

    if (!fieldslogin.password) {
      formIsValid = false;
      errors.password = LoginJSON.ErrorMsg.password;
    }

    setErrorsLogin(errors);
    return formIsValid;
  };
  let [showPassword, setShowPassword] = useState(false);
  let { users } = selector ? selector : {};
  let { loading } = users ? users : {};

  return (
    <>
      <div className="bg-black/80">
        <Loader loading={loading} />
        <div className="relative">
          <div className="min-h-screen overflow-hidden flex flex-col justify-center relative z-20">
            <div className="mx-auto w-full bg-no-repeat rounded-lg max-w-lg">
              <ScrollFixOnKeyboard/>
              <div className="mx-auto h-screen flex justify-center items-center flex-col bg-no-repeat py-0 rounded-xl relative overflow-hidden">
                <div className="w-full h-[30vh] bg-[#C11C84] absolute top-0">
                  <div className="border-l-4 border-white uppercase text-white m-4 ps-2 font-semibold">
                    <p>Enter Your Password</p>
                    <p>Enter In game Zone</p>
                  </div>
                </div>
                <div className="h-screen w-full place-items-center bg-white rounded-tl-[100px] absolute top-20">
                  <div className="mx-10 mt-20">
                    <form autoComplete="off">
                      <div className="">
                        <h1 className="font-bold text-3xl">Login</h1>
                        <p className="text-gray-500 pb-10">Sign in your account</p>
                      </div>
                      <div className="mb-2">
                        <div className="mt-0 shadow-sm relative">
                          <input
                            value={fieldslogin["userName"] || ""}
                            className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 rounded-lg bg-white text-sm md:text-base shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!errorslogin["userName"]
                              ? "border border-gray-200 placeholder-gray-500"
                              : "border border-opacity-100 border-red-500"
                              }`}
                            id="userName"
                            name="userName"
                            placeholder="Phone Number"
                            type="number"
                            onChange={inputChange}
                            style={{ fontSize: '16px' }} // Ensures font size is 16px or greater
                          />
                          {errorslogin["userName"] ? (
                            <div className="invalid-feedback text-red-500">
                              {errorslogin["userName"]}
                            </div>
                          ) : null}
                          <span className="absolute top-4 left-4 w-7 h-8 bg-[#C11C84] flex justify-center place-items-center rounded-lg">
                            <FaMobileAlt className="text-white" />
                          </span>
                        </div>
                      </div>

                      <div className="mt-0">
                        <div className="mt-1 rounded-md shadow-sm relative">
                          <input
                            value={fieldslogin["password"] || ""}
                            className={`border-1 px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white text-black rounded-lg text-sm md:text-base shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!errorslogin["password"]
                              ? "placeholder-gray-500"
                              : "border-opacity-100 border border-red-500"
                              }`}
                            id="password"
                            name="password"
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            onChange={inputChange}
                            style={{ fontSize: '16px' }} // Ensures font size is 16px or greater
                          />

                          <span className="absolute top-4 left-4 w-7 h-8 bg-[#C11C84] flex justify-center place-items-center rounded-lg">
                            <FaKey className="text-white" />
                          </span>

                          {showPassword ? (
                            <LuEye
                              onClick={() => setShowPassword((prev) => !prev)}
                              className="text-gray-500 cursor-pointer absolute right-3 bottom-6"
                            />
                          ) : (
                            <LuEyeOff
                              onClick={() => setShowPassword((prev) => !prev)}
                              className="text-gray-500 absolute cursor-pointer right-3 bottom-6"
                            />
                          )}
                        </div>
                        {errorslogin["password"] ? (
                          <div className="invalid-feedback text-red-500">
                            {errorslogin["password"]}
                          </div>
                        ) : null}
                      </div>

                      <div className="mt-0 w-64 sm:w-72 mx-auto py-6">
                        <button
                          style={{ fontFamily: "Algerian" }}
                          className="bg-[#C11C84] w-full mx-auto flex justify-center place-items-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-[#f15c23] hover:text-white focus:outline-none focus:shadow-outline-yellow active:bg-[#f15c23] transition duration-150 ease-in-out"
                          type="submit"
                          onClick={loginSubmit}
                        >
                          {LoginJSON.signin}
                          <IoMdArrowDropright className="text-xl" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
