import React from "react";
import Modal from 'react-modal';

export default function UpdateAppSettingModal(props) {

  let { updateModal, handleUpdateHideModal, inputChange, fieldsUpdate, errorsUpdate, updateAppSettingSubmit, handleFile } = props;
console.log(fieldsUpdate)

  return (

    // <Modal
    //   isOpen={updateModal}
    // >

      <div className={updateModal?`fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster`:'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center text-white justify-between rounded-lg  bg-pink-500 px-2 py-3">
              <p className="text-2xl font-bold">Update Bank Details</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleUpdateHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <form autoComplete="off">
              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" >Account Holder's Name:</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["name"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="name" name="name" placeholder="Account Holder's Name"
                    // value={fieldsUpdate.name}
                    value={fieldsUpdate && fieldsUpdate["name"] ? fieldsUpdate["name"] : ""}

                    type="text" onChange={inputChange} />
                  {errorsUpdate && errorsUpdate["name"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsUpdate["name"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" >Bank Name :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["bankName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="bankName" name="bankName" placeholder="Bank Name"
                    value={fieldsUpdate && fieldsUpdate["bankName"] ? fieldsUpdate["bankName"] : ""}
                    type="text" onChange={inputChange} />
                  {errorsUpdate && errorsUpdate["bankName"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsUpdate["bankName"]}
                    </div>
                    : null}
                </div>
              </div>
            


              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" >Account No. :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["accountNo"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="accountNo" name="accountNo" placeholder="Account No."
                    value={fieldsUpdate && fieldsUpdate["accountNo"] ? fieldsUpdate["accountNo"] : ""}
                    type="number" onChange={inputChange} />
                  {errorsUpdate && errorsUpdate["accountNo"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsUpdate["accountNo"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" >IFSC :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["ifsc"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="ifsc" name="ifsc" placeholder="IFSC"
                    value={fieldsUpdate && fieldsUpdate["ifsc"] ? fieldsUpdate["ifsc"] : ""}
                    type="text" onChange={inputChange} />
                  {errorsUpdate && errorsUpdate["ifsc"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsUpdate["ifsc"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" >UPI ID :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["upiId"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="upiId" name="upiId" placeholder="UPI ID"
                    value={fieldsUpdate && fieldsUpdate["upiId"] ? fieldsUpdate["upiId"] : ""}
                    type="text" onChange={inputChange} />
                  {errorsUpdate && errorsUpdate["upiId"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsUpdate["upiId"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="w-64 mx-auto mt-4 sm:w-72">
                <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-pink-500 border border-[#992941] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#992941] focus:shadow-outline-yellow active:bg-pink-500" type="button" onClick={updateAppSettingSubmit}>Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>


    // </Modal>



  );
}
